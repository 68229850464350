/**
 * Timetastic
 * Login page
 */
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import Footer from "src/components/footer"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/login.scss"
import "src/styles/animations/login.scss"

// SEO
const title = "Log in to Timetastic"
const description =
  "Timetastic customer log in. Manage time off work with our web or mobile apps."

// JSX
const Default = () => {

  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO
        title={title}
        useTitleTemplate={false}
        description={description}
        hrefLangs={{
          EnHref: "https://timetastic.co.uk/login/",
          UsaHref: "https://timetastic.us/login/",
          UkHref: "https://timetastic.co.uk/login/",
        }}
      />
      <Layout>
        <Header />
        <main className="c-login-main">
          <section
            className="c-section c-login-screenshots is-unanimated"
            id="screenshots"
          >
            <div className="u-inner u-inner--l c-login-screenshots__inner">
              <div>
                <h1 className="h h2 c-login-screenshots__title">
                  Log in to Timetastic
                </h1>
                <p>
                  Manage time off work with our web or mobile apps.
                </p>
                <a
                  className="c-button c-button--primary c-button--xl"
                  href="https://app.timetastic.co.uk/login/"
                  trackCtaLabel="LoginPageUsClick"
                >
                  Log in
                </a>
                <p>
                  <small>If you haven't yet signed up, you can{" "}
                    <Link
                      className="u-em-link"
                      to="/pricing/"
                      onClick={() => {
                        trackCta("LoginPageUsTrialLink")
                      }}
                    >
                      <span>try Timetastic for free</span>
                    </Link>.
                  </small>
                </p>
                <div className="c-login-qr-panel">
                  <img src="/images/login/qr-code.png" alt="Scan QR code to get the mobile app" />
                  <div>
                    <h2 className="h h4">Scan the QR code</h2>
                    <p>
                      <a 
                      className="u-em-link"
                      href="https://get.timetastic.app/"
                      rel="noreferrer noopener"
                      target="_blank"
                      onClick={() => {
                        trackCta("LoginPageUsAppClick")
                      }}
                      >
                        Or click here to get the mobile app
                      </a>
                    </p>
                    <small>(available on iOS & Android)</small>
                  </div>
                </div>
              </div>

              <div className="c-login-screenshots__tablet">
                <StaticImage
                  src="../images/no-spreadsheets-tablet.jpg"
                  width={780}
                  alt="Tablet wallchart"
                  placeholder="blurred"
                  layout="constrained"
                />
                <StaticImage
                  src="../images/hero-mobile-booking.jpg"
                  width={197}
                  alt="Mobile booking"
                  placeholder="blurred"
                  layout="constrained"
                  className="c-login-screenshots__mobile"
                />
              </div>
              <div className="c-login-screenshots__mobile-s">
                <StaticImage
                  src="../images/no-spreadsheets-mobile-s.jpg"
                  width={359}
                  alt="Mobile calendar"
                  placeholder="blurred"
                  layout="constrained"
                />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default
